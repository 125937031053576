<template>
  <div class="slide-toggle" :class="{ 'slide-toggle--expanded': props.expanded }">
    <slot></slot>
  </div>
</template>
<script setup>

const props = defineProps({
  expanded: Boolean,
});

</script>
<style lang="postcss">
.slide-toggle {

  @apply max-h-[0] opacity-0 overflow-hidden;

  &--expanded {
    animation: slide-toggle 0.6s;
    animation-fill-mode: forwards;
    opacity: 1;
  }

}
</style>